<template>
    <r-e-dialog title="新建库房" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="500px" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="库房编码" prop="stockSn">
                    <el-input v-model="formPublish.stockSn" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="库房名称" prop="stockName">
                    <el-input v-model="formPublish.stockName" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="所属公司" prop="company">
                    <el-input v-model="formPublish.company" style="width: 300px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {stockPublish} from '@/api/warehouse';
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "dialog-add-warehouse",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    stockSn: "",
                    stockName: "",
                    company: ""
                },
                rules: {
                    stockSn: [{required: true, message: '请输入库房编码', trigger: 'blur'}],
                    stockName: [{required: true, message: '请输入库房名称', trigger: 'blur'}],
                    company: [{required: true, message: '请输入所属公司', trigger: 'blur'}]
                }
            }
        },
        components: {},
        methods: {
            async openDialog() {
                let that = this;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {stockSn,stockName,company}=that.formPublish;
                        stockPublish({stockSn,stockName,company}).then(res=>{
                            MessageSuccess('添加库房成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err=>{
                            MessageError('添加库房失败');
                            loading.close();
                        });
                    }
                })
            },
            clickCancel() {
                this.$emit("handleSearch", false);
                this.$refs["formPublish"].resetFields();
                this.dialogVisible = false;
            },
        },
    }
</script>

<style scoped>

</style>